

















import { Component, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { IRespond } from '@store/modules/project-responds/interfaces/Interfaces';
import { ProjectRespondsActions } from '@store/modules/project-responds/Types';

const NSResponds = namespace('storeProjectResponds');
const NSProject = namespace('storeProject');

@Component({
    name: 'ProjectResponds',

    components: {
        BasePreloader: () => import('@components/BasePreloader'),
        BaseBoard: () => import('@components/BaseBoard'),
        BaseCardShell: () => import('@components/BaseCardShell'),
        RespondInfo: () => import('./components/RespondInfo.vue'),
        RespondsHeader: () => import('./components/RespondsHeader.vue'),
    },
})
export default class ProjectResponds extends Vue {
    @NSProject.Getter('projectId') projectId!: number;
    @NSResponds.Getter('respondsLoaded') respondsLoaded!: boolean;
    @NSResponds.Getter('projectResponds') responds!: IRespond[];
    @NSResponds.Action(ProjectRespondsActions.A_GET_PROJECT_ALL_ORDERS) getProjectAllOrders!: (id: number) => Promise<void>;

    mounted() {
        this.checkLoadData();
    }

    @Watch('projectId')
    watchProjectId() {
        this.checkLoadData();
    }

    checkLoadData() {
        if (!this.responds.length && this.projectId >= 0) {
            this.getProjectAllOrders(this.projectId);
        }
    }
}
